import React, {useState, useMemo, useEffect} from 'react';
import {Router} from '@reach/router';
import {graphql, Link, navigate} from 'gatsby';
import Fuse from 'fuse.js';
import {Helmet} from 'react-helmet';
import {shuffle} from 'lodash';
import {CSSTransitionGroup} from 'react-transition-group';

import {useDebounce} from '../../hooks';
import Layout from '../../components/layout';
import SEO from '../../components/SEO';
import StockView from "../../components/stock-view";
import {getStringSorter, ROUTES, generateRoute} from "../../common";
import Stock from "../../types/Stock";
import '../../styles/stocks.css';


export const query = graphql`
    query StocksPageQuery {
      stocks: allPseStocks (filter: { status: { eq: "OPEN" } }) {
        nodes {
          company_name
          ticker_symbol
          status
        }
      }
      site {
        siteMetadata {
          defaultTitle: title
          defaultDescription: description
          siteUrl
          defaultImage: image
          titleTemplate
          defaultKeywords: keywords
        }
      }
    }
`

const sortStockList = getStringSorter('ticker_symbol');

function NavigationButtons({previousStock, nextStock, onPreviousOrNext, onRandomSelect}) {

    function handlePreviousStock() {
        // @ts-ignore
        plausible('StocksPrevious');

        onPreviousOrNext(previousStock);
    }

    function handleNextStock() {
        // @ts-ignore
        plausible('StocksNext');

        onPreviousOrNext(nextStock);
    }

    const activeBtnClasses = ['hover:bg-gray-50', 'text-gray-500'];
    const inactiveBtnClasses = ['text-gray-300', 'cursor-not-allowed'];

    const previousBtnClasses = previousStock ? activeBtnClasses : inactiveBtnClasses;
    const nextBtnClasses = nextStock ? activeBtnClasses : inactiveBtnClasses;

    return (
        <div>
            <nav className="relative z-0 inline-flex shadow-sm -space-x-px" aria-label="Pagination">
                <button onClick={handlePreviousStock}
                        disabled={!previousStock}
                        className={`relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium ${previousBtnClasses.join(' ')}`}>
                    <span className="sr-only">Previous</span>
                    <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                         fill="currentColor"
                         aria-hidden="true">
                        <path fillRule="evenodd"
                              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                              clipRule="evenodd"/>
                    </svg>
                </button>
                <button onClick={onRandomSelect}
                        className="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium hover:bg-gray-50">
                    <span className="sr-only">Random select stock</span>
                    <svg className="h-5 w-5" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                        <path d="m48 144 208-120 208 120-208 344z" fill="#e8edf1"/>
                        <path d="m256 264v224l-208-120v-224z" fill="#e0e2e5"/>
                        <path d="m256 264v224l208-120v-224z" fill="#d9dbdd"/>
                        <g fill="#6a7073">
                            <ellipse cx="256" cy="136" rx="32" ry="24"/>
                            <ellipse cx="311.998" cy="296.004" rx="28.331" ry="20.331"
                                     transform="matrix(.763 -.646 .646 .763 -117.392 271.815)"/>
                            <ellipse cx="407.864" cy="335.927" rx="28.381" ry="20.172"
                                     transform="matrix(.765 -.645 .645 .765 -120.495 341.993)"/>
                            <ellipse cx="199.887" cy="392" rx="20.331" ry="28.331"
                                     transform="matrix(.646 -.763 .763 .646 -228.419 291.139)"/>
                            <ellipse cx="200.002" cy="296.004" rx="20.331" ry="28.331"
                                     transform="matrix(.646 -.763 .763 .646 -155.131 257.28)"/>
                            <ellipse cx="103.994" cy="240" rx="20.331" ry="28.331"
                                     transform="matrix(.646 -.763 .763 .646 -146.35 164.22)"/>
                            <ellipse cx="104.136" cy="335.927" rx="20.172" ry="28.381"
                                     transform="matrix(.645 -.765 .765 .645 -219.819 199.015)"/>
                        </g>
                    </svg>
                </button>
                <button onClick={handleNextStock}
                        disabled={!nextStock}
                        className={`relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium ${nextBtnClasses.join(' ')}`}>
                    <span className="sr-only">Next</span>
                    <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                         fill="currentColor"
                         aria-hidden="true">
                        <path fillRule="evenodd"
                              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                              clipRule="evenodd"/>
                    </svg>
                </button>
            </nav>
        </div>
    )
}

interface StockListProp {
    stocks: Stock[];
    selectedStock?: Stock,
    searchTerm?: string,
    onSelectStock: (stock: Stock) => void,
    onSearch: (e: React.KeyboardEvent<HTMLInputElement>) => void,
    onClose?: () => void
}

// on mobile view the stock list is mounted/unmounted, so we pass the searchTerm
// so we could set it as the defaultValue of the search box
function StockList({stocks, selectedStock, searchTerm, onSelectStock, onSearch, onClose}: StockListProp) {
    return (
        <>
            <div className="px-6 pb-4">
                {onClose && <div className="flex justify-end">
                    <button
                        onClick={onClose}
                        className="-mr-2 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-600">
                        <span className="sr-only">Close stock list</span>
                        <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                             stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                  d="M6 18L18 6M6 6l12 12"/>
                        </svg>
                    </button>
                </div>}
                <h4 className="font-medium text-gray-700">Stocks list</h4>
                <form className="mt-2 flex space-x-4" action="#">
                    <div className="flex-1 min-w-0">
                        <label htmlFor="search" className="sr-only">Search: Enter ticker symbol or company
                            name</label>
                        <div className="relative rounded-md shadow-sm">
                            <div
                                className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <svg className="h-5 w-5 text-gray-400"
                                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                     fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd"
                                          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                          clipRule="evenodd"/>
                                </svg>
                            </div>
                            <input onKeyUp={onSearch}
                                   defaultValue={searchTerm ? searchTerm : ''}
                                   type="search" name="search"
                                   className="focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                                   placeholder="Enter ticker symbol or company name"/>
                        </div>
                    </div>
                </form>
            </div>
            <ul className="relative z-0 divide-y divide-gray-200 overflow-y-auto">
                {stocks.map((stock) => {
                    const isSelected = selectedStock && (selectedStock.ticker_symbol === stock.ticker_symbol);
                    const additionalSelectedClasses = isSelected ? ['bg-gray-100'] : ['hover:bg-gray-50 cursor-pointer'];

                    return (
                        <li key={stock.ticker_symbol}
                            onClick={() => onSelectStock(stock)}
                            className={`relative px-6 py-5 flex items-center space-x-3 focus-within:ring-2 focus-within:ring-inset focus-within:ring-pink-500 ${additionalSelectedClasses.join(' ')}`}>
                            <div className="flex-1 min-w-0">
                                <span className="absolute inset-0" aria-hidden="true"/>
                                <p className="text-sm font-medium text-gray-900">
                                    {stock.ticker_symbol}
                                </p>
                                <p className="text-sm text-gray-500 truncate">
                                    {stock.company_name}
                                </p>
                            </div>
                        </li>
                    );
                })}
            </ul>
        </>
    );
}

const RECENT_STOCKS_STORAGE_KEY = 'recent-stocks';
const RECENT_STOCKS_LIMIT = 5;

const RecentStocksStorage = {
    push(stock: Stock) {
        const existing = localStorage.getItem(RECENT_STOCKS_STORAGE_KEY);
        let recentStocks = [];
        if (existing) {
            recentStocks = JSON.parse(existing);
        }

        // Remove the duplicate first. We do this because we still want the new stock to be the first item.
        recentStocks = recentStocks.filter((tickerSymbol) => tickerSymbol !== stock.ticker_symbol);

        recentStocks = [stock.ticker_symbol, ...recentStocks];
        if (recentStocks.length > RECENT_STOCKS_LIMIT) {
            recentStocks.pop();
        }

        localStorage.setItem(RECENT_STOCKS_STORAGE_KEY, JSON.stringify(recentStocks));
    },
    getAll() {
        const existing = localStorage.getItem(RECENT_STOCKS_STORAGE_KEY);
        if (existing) {
            return JSON.parse(existing);
        }

        return [];
    }
};

function MainStocksPage(props) {
    const {stocks, siteData} = props;
    const [displayStocksList, setDisplayStocksList] = useState<Stock[]>(stocks);
    const [selectedStock, setSelectedStock] = useState<Stock>(null);
    const [previousStock, setPreviousStock] = useState<Stock>(null);
    const [nextStock, setNextStock] = useState<Stock>(null);
    const [showMobileSearch, setShowMobileSearch] = useState(false);
    const [recentlyViewedStocks, setRecentlyViewedStocks] = useState<Stock[]>([]);

    const [searchTerm, setSearchTerm] = useState('');
    const debouncedSearchTerm = useDebounce(searchTerm, 350);

    useEffect(function preSelectStockFromQueryParam() {
        if (props.query_param_ticker_symbol) {
            const preSelectStock = stocks.find((s) => s.ticker_symbol === props.query_param_ticker_symbol);
            setSelectedStock(preSelectStock);
        }
    }, [props.query_param_ticker_symbol]);

    useEffect(function loadRecentlyViewedStocksFromLocalStorage() {
        const recentlyViewedTickerSymbols = RecentStocksStorage.getAll();

        const recentStocks = [];

        // This is slow. But we are only looping RECENT_STOCKS_LIMIT worth of recent stocks. I'm fine with this.
        for (let tickerSymbol of recentlyViewedTickerSymbols) {
            let s = stocks.find((s) => s.ticker_symbol === tickerSymbol);

            if (s) {
                recentStocks.push(s);
            }
        }

        setRecentlyViewedStocks(recentStocks);
    }, []);

    useEffect(function searchStocks() {
        if (!debouncedSearchTerm.length) {
            setDisplayStocksList(stocks);
            return;
        }

        const fuse = new Fuse(stocks, {
            keys: ['ticker_symbol', 'company_name']
        });

        const searchResult = fuse.search<Stock>(debouncedSearchTerm);

        const searchResults = searchResult.map((result) => result.item);

        setDisplayStocksList(searchResults);
    }, [debouncedSearchTerm]);

    useEffect(function rememberViewedStockInStorage() {
        if (!selectedStock)
            return;

        RecentStocksStorage.push(selectedStock);
    }, [selectedStock]);

    function handleSelectStock(stock: Stock) {
        if (!stock)
            return;

        let found = false;
        let nextStock = null;
        let previousStock = null;
        for (let s of displayStocksList) {
            if (found) {
                nextStock = s;
                break;
            }

            found = (s.ticker_symbol === stock.ticker_symbol);
            if (!found) {
                previousStock = s;
            }
        }

        setSelectedStock(stock);
        setNextStock(nextStock);
        setPreviousStock(previousStock);

        navigate(generateRoute(ROUTES.STOCKS, {stock_code: stock.ticker_symbol}));
    }

    function handleMobileSelectStock(stock: Stock) {
        handleSelectStock(stock);
        setShowMobileSearch(false);
    }

    function handleSearch(e) {
        setSearchTerm(e.target.value);
    }

    function handleRandomSelect() {
        const shuffled = shuffle([...stocks]);

        // @ts-ignore
        plausible('StocksRandom');

        handleSelectStock(shuffled[0]);
    }

    const stockViewPlaceHolder = (
        <div className="flex flex-col justify-center p-4 mt-12 items-center">
            {/* desktop view */}
            <p className="hidden lg:flex text-lg text-gray-600 justify-center items-center">
                <svg className="h-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                     stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                          d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
                </svg>
                <span className="ml-2">Start by selecting a stock</span>
            </p>
            {/* mobile view */}
            <button className="flex lg:hidden text-lg justify-center items-center text-blue-500 underline"
                    onClick={() => setShowMobileSearch(true)}>
                <svg className="h-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                     stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                          d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
                </svg>
                <span className="ml-2">Start by selecting a stock</span>
            </button>

            {(recentlyViewedStocks.length > 0) && (
                <div className="text-center mt-6 border-t pt-6 px-20">
                    <p className="text-lg leading-6 text-gray-500">Or, view your recent stocks:</p>
                    <ul>
                        {recentlyViewedStocks.map((recentStock) => {
                            return <li key={recentStock.ticker_symbol} className="mt-6 lg:mt-2">
                                <Link to={generateRoute(ROUTES.STOCKS, {stock_code: recentStock.ticker_symbol})}
                                      className="text-blue-500 hover:text-blue-600 hover:underline text-base">
                                    {recentStock.ticker_symbol}
                                </Link>
                            </li>
                        })}
                    </ul>
                </div>
            )}
        </div>
    );

    return (
        <>
            <SEO title="Stocks Directory" description="View stocks listing"/>
            <Helmet title={selectedStock ? selectedStock.ticker_symbol : 'Stocks'}
                    titleTemplate={siteData.siteMetadata.titleTemplate}/>
            <>
                <div className="hidden lg:flex flex-col w-3/12 border-r border-gray-200 bg-white">
                    <div className="h-0 flex-1 flex flex-col pt-4">
                        <StockList stocks={displayStocksList}
                                   selectedStock={selectedStock}
                                   onSelectStock={handleSelectStock}
                                   onSearch={handleSearch}/>
                    </div>
                </div>

                <div className="flex-1 flex flex-col bg-gray-100 overflow-hidden">
                    <div
                        className={`${selectedStock ? 'flex' : 'hidden'} flex-grow-0 px-6 py-2 border-b border-gray-300 bg-white items-center justify-between`}>
                        <div className="flex">
                            <h3 className="text-lg">
                                {selectedStock && <span>
                                    <span
                                        className="font-semibold">{selectedStock.ticker_symbol}</span> - {selectedStock.company_name}
                                </span>}
                            </h3>
                        </div>
                        <div className="hidden lg:block">
                            <NavigationButtons previousStock={previousStock}
                                               nextStock={nextStock}
                                               onRandomSelect={handleRandomSelect}
                                               onPreviousOrNext={handleSelectStock}/>
                        </div>
                    </div>

                    <div className="relative overflow-y-auto flex-1">
                        {selectedStock ? <StockView pageContext={selectedStock}/> : stockViewPlaceHolder}
                    </div>

                    <CSSTransitionGroup
                        transitionName="search-modal"
                        transitionEnterTimeout={500}
                        transitionLeaveTimeout={500}>
                        {showMobileSearch && (
                            <div className="fixed inset-0 z-40 bg-white">
                                <div className="h-full flex-1 flex flex-col pt-4">
                                    <StockList stocks={displayStocksList}
                                               selectedStock={selectedStock}
                                               searchTerm={searchTerm}
                                               onSelectStock={handleMobileSelectStock}
                                               onSearch={handleSearch}
                                               onClose={() => setShowMobileSearch(false)}/>
                                </div>
                            </div>
                        )}
                    </CSSTransitionGroup>

                    {/* mobile view only */}
                    <div
                        className="flex-1 flex lg:hidden flex-grow-0 px-6 py-5 border-t border-gray-300 bg-white items-center justify-between">
                        {/* search button */}
                        <div className="flex-1">
                            <button onClick={() => setShowMobileSearch(true)}>
                                <span className="sr-only">Search</span>
                                <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none"
                                     viewBox="0 0 24 24"
                                     stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"/>
                                </svg>
                            </button>
                        </div>
                        <NavigationButtons previousStock={previousStock}
                                           nextStock={nextStock}
                                           onRandomSelect={handleRandomSelect}
                                           onPreviousOrNext={handleSelectStock}/>
                    </div>
                </div>
            </>
        </>
    )
}

function StocksPage({data}) {
    // for some reason nodes contain a stock data with { company_name: null, ticker_symbol: null } which would
    // cause sort to fail, so we are filtering that data.
    let stocks: Stock[] = useMemo(function () {
        return data.stocks.nodes.filter((s) => s.ticker_symbol && s.status === 'OPEN').sort(sortStockList);
    }, [data]);

    return (
        <Layout>
            <Router basepath={ROUTES.STOCKS} className="h-full flex flex-col lg:flex-row">
                <MainStocksPage stocks={stocks} siteData={data.site} path="/:query_param_ticker_symbol"/>
                <MainStocksPage stocks={stocks} siteData={data.site} path="/"/>
            </Router>
        </Layout>
    )
}


export default StocksPage
