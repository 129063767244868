import React, {useState, useEffect, useContext, useRef} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Table from 'antd/lib/table';
import dateFormat from 'date-fns/format';
import Tooltip from 'antd/lib/tooltip';
import InputNumber from 'antd/lib/input-number';
import Button from 'antd/lib/button';
import Form from 'antd/lib/form';
import DatePicker from 'antd/lib/date-picker';
import message from 'antd/lib/message';
import Skeleton from 'antd/lib/skeleton';
import {DownloadOutlined, HistoryOutlined} from '@ant-design/icons';
// Antd's date rangepicker depends on moment for initial values.
import moment from 'moment';
import {format, subYears, parseISO} from 'date-fns';

import {getStockRangeData, getYearReport} from '../api';
import {useWindowSize} from '../hooks';
import {
    getSorter,
    getStringSorter,
    LOADING_STATES,
    lightweightChartColors
} from '../common';
import Downloader from '../components/downloader';
import toPercent from '../utils/toPercent';
import approximate from '../utils/approximate';
import {AppContext} from '../contexts';
import Stock from '../types/Stock';

const {RangePicker} = DatePicker;

const DOWNLOAD_ICON = <DownloadOutlined/>
const SkeletonOverride = styled.div`
    position: relative;
    
    .ant-skeleton {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 3;
        
        .ant-skeleton-title {
            margin-top: 0px;
        }
    }
`;


const tradingDateSorter = getStringSorter('trading_date');

// Typescript is giving us type "string" is not allowed as value of defaultSortOrder. I'm not sure why. It says
// in the documentation that 'descend' is a correct value. So I'm forcing TABLE_COLUMNS to any so it would
// stop complaining.
const TABLE_COLUMNS: any = [
    {
        title: 'Date',
        dataIndex: 'trading_date',
        sorter: tradingDateSorter,
        defaultSortOrder: 'descend'
    },
    {
        title: 'Open',
        dataIndex: 'open',
        sorter: getSorter('open')
    },
    {
        title: 'High',
        dataIndex: 'high',
        sorter: getSorter('high')
    },
    {
        title: 'Low',
        dataIndex: 'low',
        sorter: getSorter('low')
    },
    {
        title: 'Close',
        dataIndex: 'close',
        sorter: getSorter('close')
    },
    {
        title: 'Volume',
        dataIndex: 'volume',
        sorter: getSorter('volume')
    }
];

const CURRENT_YEAR = parseInt(dateFormat(new Date(), 'yyyy'));

function DownloadYearReport({stockCode}) {
    const [loadingState, setLoadingState] = useState(LOADING_STATES.IDLE);
    const [downloadUrl, setDownloadUrl] = useState(null);

    async function handleFinish({reportYear}) {
        setLoadingState(LOADING_STATES.LOADING);

        try {
            const {data} = await getYearReport(stockCode, reportYear);

            setDownloadUrl(data.download_url);
            setLoadingState(LOADING_STATES.IDLE);
        } catch (e) {
            setLoadingState(LOADING_STATES.IDLE);
        }
    }

    return (
        <Form layout="inline" onFinish={handleFinish}>
            <Form.Item name="reportYear"
                       rules={[{required: true, message: 'Please input report year'}]}>
                <InputNumber placeholder="Year" min={1900} max={CURRENT_YEAR}/>
            </Form.Item>
            <Form.Item>
                <Button icon={DOWNLOAD_ICON} htmlType="submit" loading={loadingState == LOADING_STATES.LOADING}/>
                <Downloader link={downloadUrl}/>
            </Form.Item>
        </Form>
    );
}

DownloadYearReport.propTypes = {
    stockCode: PropTypes.string
};

function getInitialDateRange() {
    return [
        moment().subtract(2, 'years'),
        moment()
    ];
}

function dateStringFormat(momentDate) {
    return momentDate.format('YYYY-MM-DD');
}

const HISTORICAL_TABLE_OFFSET_HEIGHT = 300;

function HistoricalData({ticker_symbol}) {
    const [dateRange, setDateRange] = useState(getInitialDateRange());

    const {priceHistory} = useContext(StockPageContext);

    const [priceData, setPriceData] = useState(priceHistory);
    const [priceDataState, setPriceDataState] = useState(LOADING_STATES.IDLE);

    // eslint-disable-next-line no-unused-vars
    const [_width, height] = useWindowSize();

    useEffect(() => {
        setPriceData(priceHistory);
    }, [priceHistory]);

    async function handleDateRangeChange(dateRange) {
        setPriceDataState(LOADING_STATES.LOADING);

        try {
            const response = await getStockRangeData(ticker_symbol, dateStringFormat(dateRange[0]), dateStringFormat(dateRange[1]));

            setPriceData(response.data.history);
        } catch (e) {
            message.error('Could not fetch historical data at this time. Please try again later.');
        }

        setPriceDataState(LOADING_STATES.IDLE);
    }

    function onFormFinish({range}) {
        setDateRange(range);
        handleDateRangeChange(dateRange);
    }

    const isFetching = priceDataState === LOADING_STATES.LOADING;

    const TableTitle = (
        <div className="flex flex-col md:flex-row">
            <div className="flex-1 inline-flex items-center">
                <HistoryOutlined className="mx-2"/> Historical Data
            </div>
            <div className="flex-none">
                <Form initialValues={{range: dateRange}} layout="inline" onFinish={onFormFinish}>
                    <Form.Item name="range">
                        <RangePicker/>
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType="submit" disabled={isFetching}>Apply</Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );

    // @ts-ignore
    return (
        <div className="rounded-lg shadow">
            <Table columns={TABLE_COLUMNS}
                   dataSource={priceData}
                   loading={isFetching}
                   title={() => TableTitle}
                   rowKey={'trading_date'}
                   pagination={false}
                   bordered={true}
                   size="middle"
                   scroll={{y: height - HISTORICAL_TABLE_OFFSET_HEIGHT}}/>
        </div>
    );
}

HistoricalData.propTypes = {
    ticker_symbol: PropTypes.string
};

const DATE_FORMAT = 'yyyy-MM-dd';

export const StockPageContext = React.createContext({
    priceHistory: [],
    statData: null,
    loadingState: LOADING_STATES.IDLE
});

function HeaderChart() {
    const chartContainer = useRef(null);
    const chart = useRef();
    const chartSeries = useRef();

    const {priceHistory, statData, loadingState} = useContext(StockPageContext);

    useEffect(() => {
        if (!chartContainer.current) return;

        if (chart.current || chartSeries.current) return;

        // @ts-ignore
        chart.current = LightweightCharts.createChart(chartContainer.current, {
            // width: chartContainer.current.clientWidth,
            height: 150,
            rightPriceScale: {
                visible: false
            },
            timeScale: {
                borderVisible: false,
            },
            layout: {
                backgroundColor: '#ffffff',
                textColor: '#4b5563',
            },
            grid: {
                vertLines: {
                    visible: false,
                },
                horzLines: {
                    visible: false,
                },
            },
            crosshair: {
                vertLine: {
                    labelVisible: false,
                },
            },
        });

        // @ts-ignore
        chartSeries.current = chart.current.addAreaSeries({
            topColor: 'rgba(33, 150, 243, 0.56)',
            bottomColor: 'rgba(33, 150, 243, 0.04)',
            lineColor: 'rgba(33, 150, 243, 1)',
            lineWidth: 2,
        });

        function businessDayToString(businessDay) {
            const month = (businessDay.month < 10 ? '0' + businessDay.month : businessDay.month)
            const day = (businessDay.day < 10 ? '0' + businessDay.day : businessDay.day)

            return businessDay.year + '-' + month + '-' + day;
        }

        const toolTipWidth = 80;
        const toolTipHeight = 80;
        const toolTipMargin = 15;

        const toolTip = document.createElement('div');
        toolTip.className = 'absolute bg-white px-3 py-2 border rounded box-border z-50 pointer-events-none text-center';
        toolTip.style.display = 'none';
        chartContainer.current.appendChild(toolTip);

        // @ts-ignore
        chart.current.subscribeCrosshairMove(function (param) {
            if (param.point === undefined || !param.time || param.point.x < 0 || param.point.x > chartContainer.current.clientWidth || param.point.y < 0 || param.point.y > chartContainer.current.clientHeight) {
                toolTip.style.display = 'none';
            } else {
                toolTip.style.display = 'block';

                const dateStr = businessDayToString(param.time);
                const price = param.seriesPrices.get(chartSeries.current);
                toolTip.innerHTML = '<div class="text-base">' + price + '</div><div class="text-sm text-gray-400">' + dateStr + '</div>';

                // @ts-ignore
                const coordinate = chartSeries.current.priceToCoordinate(price);
                if (coordinate === null) {
                    return;
                }

                let shiftedCoordinate = param.point.x - 50;
                shiftedCoordinate = Math.max(0, Math.min(chartContainer.current.clientWidth - toolTipWidth, shiftedCoordinate));

                let coordY = coordinate - toolTipHeight - toolTipMargin;
                let values = chartContainer.current.clientHeight - toolTipHeight - toolTipMargin;
                let values1 = coordinate + toolTipMargin;

                const coordinateY = coordY > 0 ? coordY : Math.max(0, Math.min(values, values1));
                toolTip.style.left = shiftedCoordinate + 'px';
                toolTip.style.top = coordinateY + 'px';
            }
        });
    }, [chartContainer]);

    // Calculate how should we color the graph
    useEffect(() => {
        if (!statData)
            return;

        const pricePercentChange = statData.pricePercentChange;
        if (isNaN(pricePercentChange)) {
            // @ts-ignore
            chartSeries.current.applyOptions(lightweightChartColors.default);
            return;
        }

        const isDown = pricePercentChange < 0;
        const isUp = pricePercentChange > 0;

        if (isDown) {
            // @ts-ignore
            chartSeries.current.applyOptions(lightweightChartColors.down);
        } else if (isUp) {
            // @ts-ignore
            chartSeries.current.applyOptions(lightweightChartColors.up);
        } else {
            // @ts-ignore
            chartSeries.current.applyOptions(lightweightChartColors.default);
        }
    }, [statData]);

    // Set priceHistory as graph data
    useEffect(() => {
        if (!chart.current || !chartSeries.current)
            return;

        // @ts-ignore
        chartSeries.current.setData(priceHistory.map((p) => ({time: p.trading_date, value: p.close})));
    }, [priceHistory]);

    return (
        <div>
            <SkeletonOverride>
                {loadingState === LOADING_STATES.LOADING && <Skeleton paragraph={false} active={true}/>}
            </SkeletonOverride>
            <div className="relative border-b border-gray-200" ref={chartContainer}/>
        </div>
    );
}

function StatDisplay({statData, priceHistory}) {
    const hasValue = (statData && !isNaN(statData.pricePercentChange));
    const percentChangeValue = hasValue ?
        toPercent(statData.pricePercentChange) : 'NA';

    const maxIndex = priceHistory.length - 1;
    const averageByDays = 9;
    const stopIndex = maxIndex - averageByDays;

    let open = '-';
    let high = '-';
    let low = '-';
    let close;
    let approximateVolume = '-';
    let approximateValue = '-';
    let approximateAverageVolume = '-';
    let approximateAverageValue = '-';

    let totalVolume = 0;
    let totalValue = 0;
    for (let i = (priceHistory.length - 1); i >= stopIndex && priceHistory.length; i--) {
        totalVolume += priceHistory[i].volume;
        totalValue += priceHistory[i].volume * priceHistory[i].close;
    }

    const averageVolume = totalVolume / averageByDays;
    const averageValue = totalValue / averageByDays;
    let percentChangeContainerStyle = '';
    let priceActionTimestamp = null;

    let isDown = false;
    let isUp = false;

    if (statData) {
        const timestamp = statData.latestPrice.timestamp ?? statData.latestPrice.trading_date + ' 16:00:00';
        priceActionTimestamp = 'As of ' + format(parseISO(timestamp), 'MMMM d, yyyy h:mm aaaa');

        isDown = statData.pricePercentChange < 0;
        isUp = statData.pricePercentChange > 0;

        open = statData.latestPrice.open;
        high = statData.latestPrice.high;
        low = statData.latestPrice.low;
        close = statData.latestPrice.close;

        approximateVolume = approximate(statData.latestPrice.volume);
        approximateValue = approximate(statData.latestPrice.volume * close);
        approximateAverageVolume = approximate(averageVolume);
        approximateAverageValue = approximate(averageValue);
    }

    if (isDown) percentChangeContainerStyle = 'bg-red-100 text-red-800';
    if (isUp) percentChangeContainerStyle = 'bg-green-100 text-green-800';

    return (
        <>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
                Latest activity
            </h3>

            {priceActionTimestamp ? <p>{priceActionTimestamp}</p> : <p dangerouslySetInnerHTML={{__html: '&nbsp;'}}/>}

            <dl className="mt-5 grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow divide-y divide-gray-200 md:grid-cols-4 md:divide-y-0 md:divide-x">
                <div>
                    <div className="px-4 py-5 sm:p-6">
                        <dt className="text-sm font-normal text-gray-900">
                            Close
                        </dt>
                        <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                            <div className="flex items-baseline text-lg font-semibold text-blue-800">
                                {close ? close : '-'}
                            </div>

                            <div
                                className={`inline-flex items-baseline px-1.5 py-0.5 rounded-full text-xs md:mt-2 lg:mt-0 ${percentChangeContainerStyle}`}>
                                {isDown && (
                                    <>
                                        <svg className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500"
                                             fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                                            <path fillRule="evenodd"
                                                  d="M14.707 10.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 12.586V5a1 1 0 012 0v7.586l2.293-2.293a1 1 0 011.414 0z"
                                                  clipRule="evenodd"/>
                                        </svg>
                                        <span className="sr-only">Decreased by</span>
                                    </>
                                )}

                                {isUp && (
                                    <>
                                        <svg
                                            className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"
                                            fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                                            <path fillRule="evenodd"
                                                  d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z"
                                                  clipRule="evenodd"/>
                                        </svg>
                                        <span className="sr-only">Increased by</span>
                                    </>
                                )}

                                {percentChangeValue}
                            </div>
                        </dd>
                    </div>
                </div>

                <div>
                    <div className="px-4 py-5 sm:p-6">
                        <dt className="text-sm font-normal text-gray-900">
                            Open
                        </dt>
                        <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                            <div className="flex items-baseline text-lg font-semibold text-blue-800">
                                {open}
                            </div>
                        </dd>
                    </div>
                </div>

                <div>
                    <div className="px-4 py-5 sm:p-6">
                        <dt className="text-sm font-normal text-gray-900">
                            High
                        </dt>
                        <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                            <div className="flex items-baseline text-lg font-semibold text-blue-800">
                                {high}
                            </div>
                        </dd>
                    </div>
                </div>

                <div>
                    <div className="px-4 py-5 sm:p-6">
                        <dt className="text-sm font-normal text-gray-900">
                            Low
                        </dt>
                        <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                            <div className="flex items-baseline text-lg font-semibold text-blue-800">
                                {low}
                            </div>
                        </dd>
                    </div>
                </div>
            </dl>

            <dl className="mt-5 grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow divide-y divide-gray-200 md:grid-cols-4 md:divide-y-0 md:divide-x">
                <div>
                    <div className="px-4 py-5 sm:p-6">
                        <dt className="text-sm font-normal text-gray-900">
                            Volume
                        </dt>
                        <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                            <div className="flex items-baseline text-lg font-semibold text-blue-800">
                                {approximateVolume}
                            </div>
                        </dd>
                    </div>
                </div>

                <div>
                    <div className="px-4 py-5 sm:p-6">
                        <dt className="text-sm font-normal text-gray-900">
                            Value
                        </dt>
                        <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                            <div className="flex items-baseline text-lg font-semibold text-blue-800">
                                {approximateValue}
                            </div>
                        </dd>
                    </div>
                </div>

                <div>
                    <div className="px-4 py-5 sm:p-6">
                        <dt className="text-sm font-normal text-gray-900">
                            Average Volume
                        </dt>
                        <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                            <div className="flex items-baseline text-lg font-semibold text-blue-800">
                                <Tooltip title={`${averageByDays}-day average volume`}>
                                    {approximateAverageVolume}
                                </Tooltip>
                            </div>
                        </dd>
                    </div>
                </div>

                <div>
                    <div className="px-4 py-5 sm:p-6">
                        <dt className="text-sm font-normal text-gray-900">
                            Average Value
                        </dt>
                        <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                            <div className="flex items-baseline text-lg font-semibold text-blue-800">
                                <Tooltip title={`${averageByDays}-day average value`}>
                                    {approximateAverageValue}
                                </Tooltip>
                            </div>
                        </dd>
                    </div>
                </div>
            </dl>
        </>
    );
}

function StockView({pageContext}) {
    const appContext = useContext(AppContext);
    const {ticker_symbol, company_name, status} = pageContext;
    const stock: Stock = {company_name, status, ticker_symbol};

    const priceHistory = appContext.getPriceDaily(stock);
    const [statData, setStatData] = useState(null);
    const [loadingState, setLoadingState] = useState(LOADING_STATES.IDLE);

    useEffect(() => {
        async function _() {
            // If data is in context (cache) just use that
            const priceHistory = appContext.getPriceDaily(stock);
            if (priceHistory.length)
                return;

            setLoadingState(LOADING_STATES.LOADING);

            const today = format(new Date(), DATE_FORMAT);
            const yearAgo = format(subYears(new Date(), 2), DATE_FORMAT);

            const response = await getStockRangeData(stock.ticker_symbol, yearAgo, today);

            appContext.setPriceDaily(stock, response.data.history);
            setLoadingState(LOADING_STATES.IDLE);
        }

        _();
    }, [stock.ticker_symbol]);

    useEffect(() => {
        if (priceHistory.length === 0) {
            setStatData(null);
            return;
        }

        const latestPrice = priceHistory[priceHistory.length - 1];
        const previousPrice = priceHistory[priceHistory.length - 2];
        if (!previousPrice) {
            setStatData({latestPrice: latestPrice});
            return;
        }

        const price_delta = latestPrice.close - previousPrice.close
        const percentChange = 100 * (price_delta / previousPrice.close)

        const statData = {
            latestPrice: latestPrice,
            pricePercentChange: percentChange
        };

        setStatData(statData);
    }, [priceHistory]);

    return (
        <StockPageContext.Provider
            value={{priceHistory: priceHistory, statData: statData, loadingState: loadingState}}>
            <HeaderChart/>
            <div className="px-10 lg:px-40 mt-10 mb-6">
                <StatDisplay statData={statData} priceHistory={priceHistory}/>
            </div>
            <div className="hidden lg:block px-10 lg:px-40 pb-6">
                <HistoricalData ticker_symbol={ticker_symbol}/>
            </div>
        </StockPageContext.Provider>
    )
}

StockView.propTypes = {
    pageContext: PropTypes.shape({
        company_name: PropTypes.string,
        ticker_symbol: PropTypes.string,
        status: PropTypes.string
    })
}

export default React.memo(StockView);
