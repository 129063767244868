import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';
import { useStaticQuery, graphql } from "gatsby";

SEO.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    article: PropTypes.bool,
    keywords: PropTypes.string
}
SEO.defaultProps = {
    title: null,
    description: null,
    image: null,
    article: false,
    keywords: null
}

function SEO({ title, description, image, article, keywords }) {
    const { pathname } = useLocation();
    const { site } = useStaticQuery(query);
    const {
        defaultTitle,
        defaultDescription,
        siteUrl,
        defaultImage,
        titleTemplate,
        defaultKeywords
    } = site.siteMetadata;

    const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        image: `${siteUrl}${image || defaultImage}`,
        url: `${siteUrl}${pathname}`,
        keywords: keywords || defaultKeywords
    };

    const isHomepage = pathname === '/';
    let _titleTemplate = isHomepage ? title : titleTemplate;

    return (
        <Helmet title={seo.title} titleTemplate={_titleTemplate}>
            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.image} />
            <meta name="keywords" content={seo.keywords}/>

            {seo.url && <meta property="og:url" content={seo.url} />}
            {(article ? true : null) && <meta property="og:type" content="article" />}
            {seo.title && <meta property="og:title" content={seo.title} />}
            {seo.description && (
                <meta property="og:description" content={seo.description} />
            )}
            {seo.image && <meta property="og:image" content={seo.image} />}
            <meta name="twitter:card" content="summary_large_image" />
            {seo.title && <meta name="twitter:title" content={seo.title} />}
            {seo.description && (
                <meta name="twitter:description" content={seo.description} />
            )}
            {seo.image && <meta name="twitter:image" content={seo.image} />}
        </Helmet>
    );
}

export default SEO;

const query = graphql`
    query SEO {
        site {
            siteMetadata {
                defaultTitle: title
                defaultDescription: description
                siteUrl
                defaultImage: image
                titleTemplate
                defaultKeywords: keywords
            }
        }
    }
`
